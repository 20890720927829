import * as React from 'react';
import Helmet from 'react-helmet';

import { Hero, Layout, SEO } from '..';
import CTA from './success-cta';
import HeroImage from './success-hero-image';

const SuccessPage = () => (
  <Layout isSuccess hideForm>
    <SEO
      title="Success | Frontline Removals"
      description="Frontline Removals: Port Macquarie’s trusted, experienced shipping and storage provider. We’ve been around for yonks. Get in touch with our expert team."
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Hero image={<HeroImage />} cta={<CTA />} />
  </Layout>
);

export default SuccessPage;
